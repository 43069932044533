<template>
  <div
    class="w-full min-h-full py-5 bg-[#f0f0f0]"
    :class="loader ? 'items-center justify-center' : ''"
  >
    <!---------------------------- loader ----------------------->
    <template class="w-full h-full" v-if="loader">
      <div class="w-336 mx-auto px-5">
        <div class="p-5 px-8 flex justify-center gap-x-8">
          <div
            class="
              bg-dokBlue-light
              w-8
              h-8
              rounded-full
              animate-bounce
              blue-circle
            "
          ></div>
          <div
            class="
              bg-rosDok-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              red-circle
            "
          ></div>
          <div
            class="
              bg-dokBlue-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              green-circle
            "
          ></div>
        </div>
      </div>
    </template>
    <!---------------------------- data ----------------------->
    <div
      class="
        container
        bg-white
        mt-5
        container
        shadow-xl
        rounded
        border border-solid border-gray-200
      "
      v-if="!loader"
    >
      <!---------------------------- header ----------------------->
      <div class="card">
        <div class="card-title text-center mt-4">
          <div class="flex justify-between">
            <h1 class="text-dokBlue-ultra font-EffraM text-2xl capitalize">
              {{ ticket.sujet }}
            </h1>
            <h5 class="font-EffraM text-2xl text-blackdok">
              {{ ticket.type }}
            </h5>
          </div>
          <div
            v-if="ticket.status == 1"
            class="
              flex
              items-center
              bg-blue-700
              text-white text-sm
              font-bold
              px-4
              py-3
              mx-auto
              w-6/12
            "
            role="alert"
          >
            <svg
              class="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
              />
            </svg>
            <p class="font-EffraB">En Cours</p>
          </div>
          <div
            v-if="ticket.status == 2"
            class="
              flex
              items-center
              bg-green-800
              text-white text-sm
              font-bold
              px-4
              py-3
              mx-auto
              w-6/12
            "
            role="alert"
          >
            <svg
              class="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
              />
            </svg>
            <p class="font-EffraB">
              Ce ticket est marqué comme résolu par le support.
            </p>
          </div>
          <div
            v-if="ticket.status == 3"
            class="
              flex
              items-center
              bg-red-800
              text-white text-sm
              font-bold
              px-4
              py-3
              mx-auto
              w-6/12
            "
            role="alert"
          >
            <svg
              class="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
              />
            </svg>
            <p class="font-EffraB">
              Ce ticket est marqué comme non résolu par le support.
            </p>
          </div>
          <br />
          <div
            v-if="
              String(tickets[tickets.length - 1].ownerID) !=
                String($auth.getUserInfo()._id) ||
              ticket.status == 3 ||
              ticket.status == 2 ||
              tickets.length > 3
            "
            class="items-center text-base font-EffraB mx-auto w-full"
          >
            <span class="mx-2"
              >Est-ce que notre réponse vous a été utile ?
            </span>
            <button
              class="
                mx-3
                cursor-pointer
                bg-transparent
                shadow-sm
                hover:shadow-lg
                rounded-full
                border-0
                w-12
                h-12
              "
              :class="
                ticket.rating ? (ticket.rating == 1 ? 'bg-gray-300' : '') : ''
              "
              @click="feed_back(1)"
            >
              <i class="fa fa-thumbs-up bg-transparent text-blue-600"></i>
            </button>
            <button
              class="
                mx-2
                cursor-pointer
                bg-transparent
                shadow-sm
                hover:shadow-lg
                rounded-full
                border-0
                w-12
                h-12
              "
              :class="
                ticket.rating ? (ticket.rating == -1 ? 'bg-gray-300' : '') : ''
              "
              @click="feed_back(-1)"
            >
              <i class="fa fa-thumbs-down bg-transparent text-red-700"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-body grid gap-y-1">
        <!---------------------------- messages ----------------------->
        <div v-for="(ticket, index) in tickets" :key="index">
          <div
            v-if="String(ticket.ownerID) == String($auth.getUserInfo()._id)"
            class="flex flex-row"
            :class="
              parseInt(index) < tickets.length - 1
                ? tickets[parseInt(index) + 1].ownerID ==
                  String($auth.getUserInfo()._id)
                  ? ''
                  : 'mb-5'
                : ''
            "
          >
            <div class="w-1/12">
              <img
                class="ml-5 w-16 h-16 rounded-full mr-3"
                :src="$checkAvatar($auth.getUserInfo().image)"
              />
            </div>
            <div
              class="
                w-9/12
                flex
                justify-between
                items-end
                rounded-lg
                py-4
                pl-5
                px-1
                bg-dokBlue-ultra
              "
            >
              <div class="flex flex-col w-10/12">
                <strong class="text-white font-EffraM pl-2 pb-2">{{
                  $auth.getUserInfo().firstName +
                  " " +
                  $auth.getUserInfo().lastName
                }}</strong>
                <span
                  class="
                    text-white
                    font-EffraR
                    pl-5
                    pr-3
                    break-words
                    text-justify
                  "
                  >{{ ticket.message }}</span
                >
                <span v-if="ticket.imageUrl != null">
                  <a
                    class="cursor-pointer text-white font-EffraR pl-5 pr-3"
                    style="text-decoration: underline"
                    @click="openWindow(ticket.imageUrl)"
                    >Document</a
                  >
                </span>
              </div>
              <span
                class="
                  text-white
                  font-EffraL
                  text-sm
                  font-semibold
                  w-2/12
                  flex
                  justify-end
                  pr-5
                "
                >{{
                  Math.abs(
                    new Date(ticket.created_at).getDay() -
                      new Date(Date.now()).getDay()
                  ) < 2 &&
                  new Date(ticket.created_at).getMonth() ==
                    new Date(Date.now()).getMonth()
                    ? moment()(ticket.created_at).fromNow()
                    : moment()(ticket.created_at).format("DD - MMMM - YY")
                }}</span
              >
            </div>
          </div>

          <!-- Reply Row -->
          <div
            v-else
            class="flex flex-row w-full"
            :class="
              parseInt(index) < tickets.length - 1
                ? tickets[parseInt(index) + 1].ownerID ==
                  String($auth.getUserInfo()._id)
                  ? 'mb-5'
                  : ''
                : ''
            "
          >
            <div class="w-2/12"></div>
            <div
              class="
                w-10/12
                flex
                justify-between
                items-end
                rounded-lg
                py-4
                pl-5
                px-1
                bg-blackdok
              "
            >
              <div class="flex flex-col w-10/12">
                <strong class="text-white font-EffraR pl-2 pb-2">
                  Équipe dok.ma
                </strong>
                <span
                  class="text-white font-EffraR pl-5 break-words text-justify"
                  >{{ ticket.message }}</span
                >
                <span v-if="ticket.imageUrl != null">
                  <a
                    class="cursor-pointer text-white font-EffraR pl-5 pr-3"
                    style="text-decoration: underline"
                    @click="openWindow(ticket.imageUrl)"
                    >Document</a
                  >
                </span>
              </div>
              <span
                class="
                  text-white
                  font-EffraL
                  text-sm
                  font-semibold
                  w-2/12
                  flex
                  justify-end
                  pr-5
                "
                >{{
                  Math.abs(
                    new Date(ticket.created_at).getDay() -
                      new Date(Date.now()).getDay()
                  ) < 2 &&
                  new Date(ticket.created_at).getMonth() ==
                    new Date(Date.now()).getMonth()
                    ? moment()(ticket.created_at).fromNow()
                    : moment()(ticket.created_at).format("DD - MMMM - YY")
                }}</span
              >
            </div>
            <div class="w-1/12">
              <img class="w-16 h-16 rounded-full ml-3" src="/no-avatar.png" />
            </div>
          </div>
        </div>

        <!---------------------------- autoreply Row ----------------------->
        <div
          v-if="
            String(tickets[tickets.length - 1].ownerID) ==
              String($auth.getUserInfo()._id) && ticket.status == 1
          "
          class="flex w-full mt-5 text-center"
        >
          <div class="w-1/12"></div>
          <div
            class="
              flex
              justify-between
              rounded-lg
              bg-blackdok
              py-3
              px-2
              text-center
              items-center
              w-10/12
            "
          >
            <span
              class="
                font-EffraM
                pl-3
                bg-blackdok
                text-center
                items-center
                text-rosDok-ultra
                w-full
              "
              >Nous venons de recevoir votre demande, nous vous répondrons dans
              les plus brefs délais au sujet de votre ticket.</span
            >
          </div>
          <div class="w-1/12"></div>
        </div>

        <!---------------------------- reply Row ----------------------->
        <div class="flex flex-row justify-center items-center mt-5">
          <template v-if="ticket.status == 1">
            <textarea
              class="
                w-full
                border border-solid border-gray-400
                resize-none
                rounded-cu
                px-5
                py-2
                text-base
                placeholder-gray-800
                mr-2
              "
              rows="4"
              v-model="formData.message"
              placeholder="Répondre Ici ..."
            ></textarea>
            <button
              class="
                cursor-pointer
                bg-transparent
                shadow-sm
                hover:shadow-lg
                rounded-full
                border-0
                w-16
                h-16
                mx-2
              "
              @click="submit()"
            >
              <i
                class="fa fa-paper-plane bg-transparent text-green-700"
                style="font-size: 20px"
              ></i>
            </button>
            <div
              class="
                flex
                items-center
                justify-center
                bg-transparent
                shadow-sm
                hover:shadow-lg
                rounded-full
                border-0
                w-16
                h-16
                mx-2
              "
              :class="!file ? '' : 'bg-red-100'"
            >
              <label>
                <i
                  class="
                    fa fa-paperclip
                    bg-transparent
                    cursor-pointer
                    text-yellow-600
                  "
                  style="font-size: 20px"
                ></i>
                <input
                  type="file"
                  class="hidden"
                  id="file"
                  ref="file"
                  v-on:change="setMessageImage()"
                />
              </label>
            </div>
            <button
              class="
                cursor-pointer
                bg-transparent
                shadow-sm
                hover:shadow-lg
                rounded-full
                border-0
                w-16
                h-16
                mx-2
              "
              @click="requestvideo()"
            >
              <i
                class="fa fa-video bg-transparent text-blue-700"
                style="font-size: 20px"
              ></i>
            </button>
          </template>
        </div>
      </div>
    </div>

    <!---------------------------- modal ----------------------->
    <div
      v-if="show"
      class="
        min-w-screen
        h-screen
        animated
        fadeIn
        faster
        fixed
        left-0
        top-0
        flex
        justify-center
        items-center
        inset-0
        z-50
        outline-none
        focus:outline-none
      "
    >
      <div
        class="
          w-auto
          p-5
          relative
          mx-auto
          my-auto
          rounded-xl
          shadow-lg
          bg-white
        "
      >
        <div class="">
          <div class="text-center flex-auto justify-center">
            <img class="w-600 h-600" :src="document" />
            <div class="mt-2 text-center space-x-4 md:block">
              <button
                @click="show = false"
                class="
                  bg-red-700
                  px-10
                  py-3
                  shadow-sm
                  tracking-wider
                  border
                  text-white
                  rounded-full
                  hover:shadow-md
                  cursor-pointer
                "
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
const loader = () => import("@/views/global-components/loader");

export default {
  data() {
    return {
      tickets: [],
      ticket: {},
      formData: { ticketId: this.$route.params.id },
      loader: true,
      file: "",
      document: "",
      show: false,
    };
  },
  components: { loader },

  async mounted() {
    try {
      const { data } = await this.$store.dispatch("support/GET_LIST_MESSAGE", {
        id: this.$route.params.id,
      });
      this.tickets = data;
      const { ticket } = await this.$store.dispatch("support/GET_TICKET", {
        id: this.$route.params.id,
      });
      this.ticket = ticket;
    } catch (error) {
      //console.log(error);
    } finally {
      this.loader = false;
    }
  },

  methods: {
    moment() {
      moment.locale("fr");
      return moment;
    },

    openWindow(image) {
      this.document = "https://api.dok.ma/uploads/" + image;
      this.show = true;
    },

    async submit() {
      if ((this.formData.message && this.formData.message != "") || this.file) {
        try {
          this.loader = true;
          if (this.file) {
            this.formData.file = this.file;
          }
          const { data, ok } = await this.$store.dispatch(
            "support/STORE_MESSAGE",
            { formData: this.formData }
          );
          if (ok) {
            this.tickets.push(data);
            this.formData = { ticketId: this.$route.params.id };
            this.file = null;
          }
        } finally {
          this.loader = false;
        }
      }
    },

    async setMessageImage() {
      this.file = this.$refs.file.files[0];
    },

    async requestvideo() {
      try {
        this.loader = true;
        this.formData.message = "*Demande d'un Live Chat*";
        const { data, ok } = await this.$store.dispatch(
          "support/STORE_MESSAGE",
          { formData: this.formData }
        );
        if (ok) {
          this.tickets.push(data);
          this.formData = { ticketId: this.$route.params.id };
        }
      } finally {
        this.loader = false;
      }
    },

    async feed_back(stat) {
      try {
        this.loader = true;
        const { data } = await this.$store.dispatch("support/FEED_BACK", {
          id: this.ticket._id,
          status: stat,
        });
        this.ticket = data;
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
<style scoped>
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid transparent;
  border-radius: 0px;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card .card-title {
  position: relative;
  font-weight: 600;
  margin-bottom: 10px;
}
</style>
